<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Uzcenojumi
      </h3>
    </template>
    <template v-slot:buttons>
        <template v-if="!displayForm('EditCategoryPriceRate')">
            <div class="flex items-center ml-auto">
                <template v-if="category.price_rates">
                    <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" @click="showFullList = !showFullList" />

                    <Button icon="pencil" @click="showForm('EditCategoryPriceRate')" />
                </template>

                <template v-else>
                  <Button icon="plus" text=" Uzstādīt uzcenojumu" @click="showForm('EditCategoryPriceRate')" />
                </template>
            </div>
        </template>

        <template v-else>
          <Button icon="close" @click="closeForm" />
        </template>
    </template>

    <template v-slot:content>

        <template v-if="category.price_rates && !displayForm('EditCategoryPriceRate')">
          <template v-if="showFullList">
            <div class="flex flex-col w-full">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Cena no
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Cena līdz
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Uzcenojums %
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(priceRate, index) in category.price_rates" :key="priceRate.id">
                                        <tr :class="index % 2 == 0 ? 'bg-white' : 'bg-gray-50'">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {{priceRate.price_range.from}}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                {{priceRate.price_range.to}}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <ItemText :text="priceRate.rate" />
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
          </template>
        </template>

        <template v-if="displayForm('EditCategoryPriceRate')">
            <EditCategoryPriceRate :category="category" />
        </template>
            
    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import EditCategoryPriceRate from "@/components/Settings/Category/Category/EditCategoryPriceRate"

export default {
  name: "CategoryPriceRates",
  components: {
    ItemCard,
    ItemText,
    EditCategoryPriceRate,
  },
  props: ['category'],
  data() {
    return {
      showFullList: false,
    }
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    }),
  },
  methods: {
    closeForm() {
      this.$store.dispatch("removeAllFormsForDisplay");
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  }
}
</script>
