<template>
    <form v-if="form" class="space-y-6 w-full pb-3 pt-6">

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Cena no
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Cena līdz
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Uzcenojums %
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="category.price_rates">
                                    <template v-for="(priceRate, index) in form" :key="priceRate.id">
                                        <tr :class="index % 2 == 0 ? 'bg-white' : 'bg-gray-50'">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {{priceRate.price_range.from}}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                {{priceRate.price_range.to}}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <Input type="number" v-model="priceRate.rate" placeholder="Uzcenojums %" :showPlaceholder="false" />
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-for="(priceRate, index) in form" :key="priceRate.id">
                                        <tr :class="index % 2 == 0 ? 'bg-white' : 'bg-gray-50'">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {{priceRate.from}}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                {{priceRate.to}}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <Input type="number" v-model="priceRate.rate" placeholder="Uzcenojums %" :showPlaceholder="false" />
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 flex justify-end items-end">
                <div class=" flex justify-end items-end">
                    <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="close">
                        Atcelt
                    </button>

                    <template v-if="!loading">
                        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
                            Saglabāt
                        </button>
                    </template>
                    <template v-else>
                        <Loading />
                    </template>
                </div>

            </div>
        </div>

    </form>
</template>

<script>
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Input from "@/components/Components/Input";
import Loading from "@/components/Components/Loading";

export default {
    name: "EditCategoryPriceRate",
    components: {
        Input,
        Loading,
    },
    props: ["category"],
    data() {
        return {
            form: null,
        }
    },
    mounted() {
        if (this.category.price_rates) {
            this.form = this.category.price_rates
        } else {
            this.$store.dispatch("getDefaultCategoryPriceRates").then(() => {
                this.form = this.categoryPriceRates
            })
        }
    },
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: "errors",
            categoryPriceRates: "categoryPriceRates",
        }),
        formValidation() {
            return {
                // name: {
                //     rules: ['required']
                // },
            }
        }
    },
    methods: {
        close() {
            this.$store.dispatch("removeAllFormsForDisplay");
        },
        formatData() {
            if (this.category.price_rates) {
                return this.form.map(priceRate => {
                    return {
                        price_range_id: priceRate.price_range_id,
                        rate: priceRate.rate,
                    }
                })
            }
            return this.form.map(priceRate => {
                return {
                    price_range_id: priceRate.id,
                    rate: priceRate.rate,
                }
            })
        },
        submit() {
            this.$Progress.start();

            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch('updateCategoryPriceRate', {
                    id: this.category.id,
                    data: {
                        price_rates : this.formatData(),
                    }
                })
                .then(response => {
                    this.$Progress.finish()
                    this.close()
                })
                .catch(error => {
                    this.$Progress.fail()
                })
            } else {
                this.$Progress.fail();
            }
        },
    }
}
</script>

<style>

</style>